<template>
  <div
    style="
      background-color: rgba(0, 0, 0, 0.4);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 100000;
      color: white;
    "
  >
    Loading...
  </div>
</template>

<script>
import AuthService from '@/services/AuthService.js';

export default {
  async mounted() {
    var authService = new AuthService();
    var user = await authService.signinSilentCallback();

    if (
      user?.profile?.role &&
      (/admin/.test(user.profile.role) ||
        /user/.test(user.profile.role) ||
        /dpt/.test(user.profile.role))
    ) {
      //window.location.href = '/';
    } else {
      window.location.href = '/#/error-401';
    }
  }
};
</script>

<style></style>
